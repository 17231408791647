// Import Firebase modules
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase configuration (replace with your config)
const firebaseConfig = {
  apiKey: "AIzaSyAm1FCrf5RBSCUBiB8CYM1A3F65zc7IY80",
  authDomain: "1:888187725268:ios:ba2da5ed3904349693a241.firebaseapp.com",
  projectId: "seefood-c23f4",
  storageBucket: "1:888187725268:ios:ba2da5ed3904349693a241.appspot.com",
  messagingSenderId: "888187725268",
  appId: "1:888187725268:ios:ba2da5ed3904349693a241",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export default db;
