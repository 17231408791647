// const BASE_URL = "https://seefood-407904.wl.r.appspot.com"; // Ensure this is the correct IP for your backend
const BASE_URL = " http://192.168.0.44:8080";

export async function getDishPic(
  dishName,
  cityName = null,
  restaurantName = null,
  menuID = null
) {
  console.log(dishName);
  // Helper function to capitalize the first letter of each word
  const capitalizeWords = (str) => {
    if (!str) return "";
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Capitalize the dish name before sending it
  const capitalizedDishName = capitalizeWords(dishName);

  // If restaurantName is an array, log each item
  if (Array.isArray(restaurantName)) {
    console.log("getDishPic restaurantName is an array with items:");
    restaurantName.forEach((name, index) =>
      console.log(`  [${index}]: ${name} (Type: ${typeof name})`)
    );
  }

  try {
    const response = await fetch(`${BASE_URL}/get_dish_pic`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dish_name: capitalizedDishName,
        city_name: cityName,
        restaurant_names: restaurantName, // Corrected key name
        menu_id: menuID,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error: ${response.status} - ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch detailed dish picture:", error);
    throw error;
  }
}
