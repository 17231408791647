import React, { useState, useEffect } from "react";
import "./styles/MenuItem.css"; // Separate CSS file for MenuItem
import { getDishPic } from "./backend"; // Import the backend function
import IMAGE_PLACEHOLDER from "./images/image_placeholder.jpg"; // Import the placeholder image

const capitalizeWords = (str) => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const MenuItem = ({ item, menuId }) => {
  const [imageUrl, setImageUrl] = useState(
    item.img_url?.[0] || IMAGE_PLACEHOLDER // Use placeholder if no image URL is provided
  );

  useEffect(() => {
    const fetchDishPic = async () => {
      // Fetch the dish picture if no image URL is provided
      if (!item.img_url || item.img_url.length === 0) {
        try {
          const response = await getDishPic(
            item.title,
            null, // Replace `null` with the appropriate city name if available
            null, // Replace `null` with the appropriate restaurant name if available
            menuId // Pass the menu ID
          );

          // Update the image URL if a valid response is received
          if (response.img_url && response.img_url.length > 0) {
            setImageUrl(response.img_url[0]); // Use the first image URL
          }
        } catch (error) {
          console.error("Failed to fetch dish picture:", error); // Log error if fetching fails
        }
      }
    };

    fetchDishPic();
  }, [item, menuId]); // Dependency array ensures the effect runs when item or menuId changes

  return (
    <div className="menu-card">
      <div className="menu-card-content">
        <div className="menu-text">
          <h3 className="menu-title">{capitalizeWords(item.title)}</h3>
          {item.description && (
            <p
              className="menu-description"
              title={capitalizeWords(item.description)} // Display the full description as a tooltip
            >
              {capitalizeWords(item.description)}
            </p>
          )}
          {item.price && <p className="menu-price">{item.price}</p>}
        </div>
        <div className="menu-image-container">
          <img
            src={imageUrl}
            alt={item.title} // Use item title as alternative text for the image
            className="menu-image"
            onError={() => setImageUrl(IMAGE_PLACEHOLDER)} // Fallback to placeholder image if loading fails
          />
        </div>
      </div>
    </div>
  );
};

export default MenuItem;
