import Header from "./Header";
import VideoWithComments from "./Demo";
import Navbar from "./Navbar";
import Footer from "./Footer";

function HomePage() {
  return (
    <div>
      <Navbar />
      <Header />
      <VideoWithComments />
      <Footer />
    </div>
  );
}

export default HomePage;
