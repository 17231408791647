import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import MenuPage from "./MenuPage"; // New component for dynamic menu pages
import HomePage from "./HomePage";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Dynamic route for menu pages */}
          <Route path="/menu/:menuId" element={<MenuPage />} />
          {/* Default homepage */}
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
