import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import db from "./firebaseConfig"; // Import Firestore instance
import { doc, getDoc } from "firebase/firestore";
import MenuItem from "./MenuItem"; // Import the MenuItem component
import "./styles/MenuPage.css"; // Import a CSS file for styling

const capitalizeWords = (str) => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const MenuPage = () => {
  const { menuId } = useParams(); // Assumes `menuId` could be a single ID or comma-separated IDs (e.g., "123,456,789")
  const [menusData, setMenusData] = useState([]); // To hold data for multiple menus
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const menuIds = menuId.split(","); // Split menuId string into an array of IDs
        const fetchedMenus = [];

        for (const id of menuIds) {
          const docRef = doc(db, "menu_history", id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            fetchedMenus.push({ id, data: docSnap.data() }); // Add each menu's data
          } else {
            console.warn(`Menu with ID ${id} not found.`);
          }
        }

        setMenusData(fetchedMenus); // Update state with all fetched menus
      } catch (error) {
        console.error("Error fetching menus:", error);
        setMenusData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchMenus();
  }, [menuId]);

  if (loading) return <div className="loading">Loading menus...</div>;
  if (menusData.length === 0)
    return <div className="error">No menus found.</div>;

  return (
    <div className="menu-container">
      {menusData.map((menu, menuIndex) => {
        const menuSections = Object.entries(menu.data).filter(
          ([key]) => key !== "timestamp" && key !== "is_menu"
        );

        return (
          <div key={menu.id} className="menu">
            {menuSections.map(([section, items], sectionIndex) => (
              <div key={sectionIndex} className="menu-section">
                <h2 className="section-title">{capitalizeWords(section)}</h2>
                {items.map((item, itemIndex) => (
                  <MenuItem key={itemIndex} item={item} menuId={menu.id} />
                ))}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default MenuPage;
